<!--
 * @Author: 肖槿
 * @Date: 2020-06-12 15:42:19
 * @Description: 商家审核
 * @LastEditors: 矢车
 * @LastEditTime: 2020-08-19 17:16:36
 * @FilePath: \s2b-admin\src\pages\admin\shop\merchant-review.vue
-->
<template>
  <div id="mechant-review">
    <kr-card :padding="[0, 0, 0, 0]" @rect:update="onTopCardRectUpdate">
      <merchant-review-search
        ref="reviewSearch"
        @success="searchHandler"
        :loading="listLoading"
        :tabIdx="currentTab"
      />
    </kr-card>
    <a-tabs @change="tabChangeCallback" type="card">
      <a-tab-pane tab="认证申请" key="1"></a-tab-pane>
      <a-tab-pane tab="审核记录" key="2"></a-tab-pane>
    </a-tabs>
    <a-table
      :class="currentTab === '2' ? 'detail-table' : ''"
      :key="mainTableKey"
      :scroll="{ y: tableScrollY }"
      :columns="currentTab === '1' ? ShopCertification : ShopAudit"
      :dataSource="currentTab === '1' ? certificationList : auditList"
      :loading="listLoading"
      :locale="{ emptyText: '暂无信息' }"
      :pagination="tablePage"
      @change="paginationHandler"
      row-key="id"
    >
      <template slot="result" slot-scope="row">
        <span :class="row.audit_status | certificationClass">{{
          row.audit_status | shopMargin(Certification)
        }}</span>
      </template>
      <template slot="type" slot-scope="row">{{ row.brand_type | shopMargin(Brand) }}</template>
      <template slot="audit_time" slot-scope="row">{{
        row.create_time | timeFormat('YYYY-MM-DD HH:mm')
      }}</template>
      <template slot="apply_time" slot-scope="row">{{
        row.apply_time | timeFormat('YYYY-MM-DD HH:mm')
      }}</template>
      <template slot="brand_type" slot-scope="row">
        <span v-if="row.brand_type === 1">品牌方</span>
        <span v-else-if="row.brand_type === 2">代理商</span>
        <span v-else-if="row.brand_type === 3">黑标</span>
        <span v-else>--</span>
      </template>
      <template slot="action" slot-scope="row">
        <a-button class="look" size="small" @click="showDetail(row)">查看</a-button>
      </template>
      <template slot="audit" slot-scope="row">
        <a-button
          v-auth="'business_audit_auth'"
          size="small"
          type="primary"
          @click="showDetail(row)"
          >审核</a-button
        >
      </template>
    </a-table>
    <a-drawer
      :title="currentTab === '1' ? '商家审核' : '商家审核记录'"
      placement="right"
      width="650"
      :visible="merchanDrawerVisiable"
      @close="handlemerchantInfoDrawerClose"
    >
      <div>
        <merchant-detail
          v-if="currentTab === '2'"
          ref="merchantDetail"
          type="audit"
          @certification="certificationHandler"
          @close="handlemerchantInfoDrawerClose"
        />
        <merchant-audit
          v-else
          ref="merchantDetail"
          type="certification"
          @certification="certificationHandler"
          @close="handlemerchantInfoDrawerClose"
        />
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { merchantReviewSearch } from '@/components/admin/shop';
import { getMerchantAudit, putMerchantAudit, getMerchantHistory } from '@/service/shop';
import { Certification, Margin, Brand, ShopCertification, ShopAudit } from '@/const/shop';
import merchantAudit from '@/components/admin/shop/merchant-audit';
import merchantDetail from '@/components/admin/shop/merchant-detail';

export default {
  name: 'MerchantReview',
  components: {
    merchantReviewSearch,
    merchantDetail,
    merchantAudit,
  },
  data() {
    return {
      mainTableKey: 1,
      tableScrollY: 600,
      currentTab: '1',
      listLoading: false,
      queryData: {},
      pagination: {
        page: 1,
        page_size: 20,
        total: 0,
      },
      Certification,
      Margin,
      Brand,
      ShopAudit,
      ShopCertification,
      certificationList: [],
      auditList: [],
      merchanDrawerVisiable: false,
    };
  },
  computed: {
    tablePage() {
      return {
        current: this.pagination.page,
        total: this.pagination.total,
        pageSize: this.pagination.page_size,
        hideOnSinglePage: true,
        rectHeight: 100,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        showTotal: total =>
          `共 ${total} 条记录 第 ${this.pagination.page}/${Math.ceil(
            total / this.pagination.page_size,
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
      };
    },
  },
  created() {
    const windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 300;
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    onTopCardRectUpdate(rect) {
      const windowHeight = document.documentElement.clientHeight || 1000;
      this.tableScrollY = windowHeight - 280 - rect.height;
      if (this.currentTab === '2') {
        this.tableScrollY = windowHeight - 330 - rect.height;
      }
      this.rectHeight = rect.height;
      this.mainTableKey = Math.random();
    },
    tabChangeCallback(val) {
      this.currentTab = val;
      const windowHeight = document.documentElement.clientHeight || 1000;
      this.tableScrollY = windowHeight - 280 - this.rectHeight;
      if (this.currentTab === '2') {
        this.tableScrollY = windowHeight - 330 - this.rectHeight;
      }
      this.pagination.page = 1;
      this.$refs.reviewSearch.formReset();
    },
    searchHandler(data) {
      this.queryData = data;
      this.pagination.page = 1;
      this.getTableData();
    },
    showDetail(row) {
      this.merchanDrawerVisiable = true;
      this.$nextTick(() => {
        this.$refs.merchantDetail.show(row);
      });
    },
    handlemerchantInfoDrawerClose() {
      this.merchanDrawerVisiable = false;
      this.$nextTick(() => {
        this.$refs.merchantDetail.hide();
      });
    },
    async getTableData() {
      let res = null;
      this.listLoading = true;
      delete this.queryData.page;
      console.log('----', this.pagination, this.tablePage);
      const _obj = Object.assign({}, this.queryData, this.pagination);
      delete _obj.total;
      res = this.currentTab === '1' ? await getMerchantAudit(_obj) : await getMerchantHistory(_obj);
      this.listLoading = false;
      if (res && res.res && res.res.data) {
        this.pagination.total = res.res.data.total || 0;
        const d = res.res.data.merchant_list || [];
        this.currentTab === '1' ? (this.certificationList = d) : (this.auditList = d);
        this.mainTableKey = Math.random();
      }
    },
    paginationHandler(params) {
      this.pagination.page = params.current;
      this.pagination.page_size = params.pageSize;
      this.getTableData();
    },
    async certificationHandler(obj) {
      this.listLoading = true;
      await putMerchantAudit(obj);
      this.listLoading = false;
      this.getTableData();
      this.$message.success('提交成功');
      this.merchanDrawerVisiable = false;
      this.$nextTick(() => {
        this.$refs.merchantDetail.hide();
      });
    },
  },
};
</script>
<style lang="less">
#mechant-review {
  .ant-table-wrapper {
    flex: 1;
    display: flex;
    .ant-spin-nested-loading {
      position: inherit;
      height: 100%;
      display: flex;
      overflow-y: auto;
      overflow-x: hidden;
      .ant-spin-container {
        position: inherit;
        -webkit-transition: opacity 0.3s;
        transition: opacity 0.3s;
        display: flex;
        flex-direction: column;
        width: 100%;
        .ant-table-pagination.ant-pagination {
          text-align: right;
        }
        .ant-table-fixed-header .ant-table-body-inner {
          overflow-y: auto !important;
        }
        .ant-table-body {
          min-height: calc(100vh - 320px);
        }
        .ant-table-placeholder {
          margin-top: calc(200px - 60vh);
          margin-bottom: calc(40vh - 56px);
          border: none;
        }
      }
    }
  }
  .detail-table {
    .ant-table-body {
      min-height: calc(100vh - 350px) !important;
    }
    .ant-table-placeholder {
      margin-bottom: calc(40vh - 46px) !important;
    }
  }
}
</style>
